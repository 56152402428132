import { UserType } from '@shared/enums';
import { IStudent, IStudentDto } from '@shared/interfaces';

export function mapStudentDtoToStudent(studentDto: IStudentDto): IStudent {
  const activeSchoolStructure = studentDto.schoolStructure?.[0] || null;
  const resolvedSchoolStructure = resolveSchoolStructure(studentDto);

  const hasActiveStructure = Boolean(studentDto.schoolStructure?.length);
  const hasSchoolLevelId = Boolean(studentDto.schoolLevelId);
  const hasClasses = Boolean(studentDto.classes?.length);

  let studetnClassStatus: IStudent['studentClassStatus'] = null;
  if (hasActiveStructure) {
    studetnClassStatus = 'ACTIVE';
  } else if (hasSchoolLevelId && hasClasses) {
    studetnClassStatus = 'INACTIVE';
  } else {
    studetnClassStatus = null;
  }

  const getStructureValue = (key: keyof ResolvedSchoolStructure) =>
    hasActiveStructure
      ? activeSchoolStructure?.[key]
      : resolvedSchoolStructure?.[key] || null;
  const mapEntitiesToNames = (
    entities?: { name: string }[],
    joiner: string = ', ',
  ): string => entities?.map((entity) => entity.name).join(joiner) ?? '';
  return {
    ...studentDto,
    company: getStructureValue('company') as IStudent['company'],
    campus: getStructureValue('campus') as IStudent['campus'],
    school: getStructureValue('school') as IStudent['school'],
    level: getStructureValue('level') as IStudent['level'],
    class: activeSchoolStructure?.class || null,
    academicYear: activeSchoolStructure?.academicYear || null,
    type: UserType.STUDENT,
    studentClassStatus: studetnClassStatus,
    // Utility methods for retrieving entity names
    getAllCompaniesName: () => mapEntitiesToNames(studentDto.companies),
    getAllCampusesName: () => mapEntitiesToNames(studentDto.campuses),
    getAllSchoolsName: () => mapEntitiesToNames(studentDto.schools),
    getAllLevelsName: () => mapEntitiesToNames(studentDto.levels),
    getAllClassesName: () => mapEntitiesToNames(studentDto.classes),
  };
}
/**
 * Resolves the school structure for a student based on the schoolLevelId.
 * This is useful when school structure data is not directly available in the student DTO,
 * and the student is associated with a school level.
 *
 * @param student - The student object containing school structure details.
 * @returns An object containing the resolved school, level, campus, and company information.
 */
export function resolveSchoolStructure(
  student: IStudent | IStudentDto,
): ResolvedSchoolStructure | null {
  const { schoolLevelId, schoolLevels, schools, levels, campuses, companies } =
    student;

  if (!schoolLevelId) {
    return null; // Return null if no schoolLevelId is present.
  }

  const schoolLevel = schoolLevels.find((level) => level.id === schoolLevelId);
  if (!schoolLevel) return null; // Return early if schoolLevel is not found.

  const school = schools.find((s) => s.id === schoolLevel.schoolId);
  const level = levels.find((l) => l.id === schoolLevel.levelId);
  const campus = campuses.find((c) => c.id === school?.campusId);
  const company = companies.find((co) => co.id === campus?.companyId);

  return {
    school: school || null,
    level: level || null,
    campus: campus || null,
    company: company || null,
  };
}

interface ResolvedSchoolStructure {
  school: IStudent['school'] | null;
  level: IStudent['level'] | null;
  campus: IStudent['campus'] | null;
  company: IStudent['company'] | null;
}
