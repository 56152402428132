import { Idropdown } from '../interfaces';

export function enumArrayFromEnum<T extends Record<string, U>, U>(
  enumObject: T,
): U[] {
  return Object.keys(enumObject).map((key) => enumObject[key]);
}

export function dropdownArrayFromEnum<
  T extends Record<string, U>,
  U extends string | number,
>(enumObject: T): Idropdown[] {
  return Object.keys(enumObject).map((key) => ({
    value: enumObject[key] as U,
    displayedValue: key,
  }));
}
export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum GuardianRelationship {
  FATHER = 'FATHER',
  MOTHER = 'MOTHER',
  BROTHER = 'BROTHER',
  SISTER = 'SISTER',
  UNCLE = 'UNCLE',
  AUNT = 'AUNT',
  GRANDFATHER = 'GRANDFATHER',
  GRANDMOTHER = 'GRANDMOTHER',
  PATERNAL_AUNT = 'PATERNAL AUNT',
  PATERNAL_UNCLE = 'PATERNAL UNCLE',
  MATERNAL_AUNT = 'MATERNAL AUNT',
  MATERNAL_UNCLE = 'MATERNAL UNCLE',
  OTHER = 'OTHER',
}

export enum StudentRelationship {
  SON = 'SON',
  DAUGHTER = 'DAUGHTER',
  NEPHEW = 'NEPHEW',
  NIECE = 'NIECE',
  GRANDSON = 'GRANDSON',
  GRANDDAUGHTER = 'GRANDDAUGHTER',
  OTHER = 'OTHER',
}

export enum UserType {
  STUDENT = 'STUDENT',
  GUARDIAN = 'GUARDIAN',
  PERSONNEL = 'PERSONNEL',
}
export enum ResourceStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export enum Language {
  ENGLISH = 'en',
  ARABIC = 'ar',
}

export enum GenderEnum {
  BOYS = 'BOYS',
  GIRLS = 'GIRLS',
  COMMON = 'COMMON',
}

export enum EducationalPathEnum {
  NATIONAL = 'NATIONAL',
  INTERNATIONAL = 'INTERNATIONAL',
  ACADEMY = 'ACADEMY',
}

export enum ChatTypeEnum {
  DM = 'DM',
  GROUP = 'GROUP',
  BRODCAST = 'BRODCAST',
}

export enum MessageType {
  TEXT = 'text',
  AUDIO = 'audio',
  VIDEO = 'video',
  IMAGE = 'image',
  FILE = 'file',
  GROUP_MEMBER = 'groupMember',
}

export enum ConnectionStatus {
  ONLINE = 'online',
  OFFLINE = 'offline',
}

export enum ConversationType {
  USER = 'user',
  GROUP = 'group',
}

export enum BroadcastChatBetween {
  ALL_STUDENTS = 'ALL_STUDENTS',
  ALL_GUARDIANS = 'ALL_GUARDIANS',
  ALL_STUDENTS_AND_GUARDIANS = 'ALL_STUDENTS_AND_GUARDIANS',
}

export enum DefaultRoles {
  SUPER_ADMIN = 'المشرف العام', // Super Admin
  STUDENT = 'طالب', // Student
  GUARDIAN = 'ولي الامر', // Guardian
  TEACHER = 'معلم', // Teacher
}

export enum PeriodDurationType {
  PERIOD = 'PERIOD',
  BREAK = 'BREAK',
}
