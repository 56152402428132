import { list } from 'ionicons/icons';

export const RESOURCE_PERMISSION = {
  student: {
    viewStudentsList: 1,
    viewStudentProfile: 2,
    excelExportStudents: 3,
    addNewStudent: 4,
    bulkUploadStudents: 5,
    editStudentProfile: 7,
    changePhoneNumber: 8,
    updatePassword: 9,
    deactivateActivateProfile: 10,
    unLinkGuardian: 11,
  },
  guardians: {
    viewGuardiansList: 12,
    viewGuardianProfile: 13,
    excelExportGuardians: 14,
    addNewGuardian: 15,
    bulkUploadGuardians: 16,
    updateGuardainsProfile: 18,
    changePhoneNumber: 19,
    deactivateActivateProfile: 20,
    unLinkStudent: 21,
  },
  personnel: {
    viewPersonnelList: 22,
    viewPersonnelProfile: 23,
    excelExportPersonnels: 24,
    addNewPersonnel: 25,
    bulkUploadPersonnels: 26,
    editPersonnelProfile: 28,
    changePhoneNumber: 29,
    decativateActivateProfile: 30,
  },
  rolesAndPermission: {
    viewRoleListing: 31,
    viewRoleDetail: 32,
    createRole: 33,
    updateRole: 34,
    deleteRole: 35,
  },
  company: {
    viewCompanyList: 36,
    viewCompanyDetails: 37,
    createCompany: 38,
    updateCompany: 39,
    deleteCompany: 40,
  },
  campus: {
    viewCampusList: 41,
    viewCampusDetails: 42,
    createCampus: 43,
    updateCampus: 44,
    deleteCampus: 45,
  },
  school: {
    viewSchoolList: 46,
    viewSchoolDetails: 47,
    createSchool: 48,
    updateSchool: 49,
    deleteSchool: 50,
  },
  level: {
    viewLevelList: 51,
    viewLevelDetails: 52,
    createLevel: 53,
    updateLevel: 54,
    deleteLevel: 55,
  },
  class: {
    viewClassList: 56,
    viewClassDetails: 57,
    createClass: 58,
    updateClass: 59,
    assignRemoveStudentFromClass: 60,
    deleteClass: 61,
  },
  academicYear: {
    academicYearListView: 62,
    academicYearDetailView: 63,
    academicYearCreate: 64,
    academicYearUpdate: 65,
    academicYearDelete: 66,
  },
  semester: {
    semesterDetailView: 67,
    semesterCreate: 68,
    semesterUpdate: 69,
    semesterDelete: 70,
  },
  lecture: {
    lectureListView: 71,
    lectureCreate: 72,
    lectureUpdate: 73,
    lectureDelete: 74,
  },
  announcement: {
    announcementListView: 75,
    announcementCreatePost: 76,
    announcementCreateNotification: 77,
    announcementCreateSms: 78,
    announcementUpdatePost: 79,
    announcementUpdateNotification: 80,
    announcementUpdateSms: 81,
    announcementDeletePost: 82,
    announcementDeleteNotification: 83,
    announcementDeleteSms: 84,
    viewPostOriginatorInfo: 151,
  },
  chat: {
    chatListView: 85,
    chatCreate: 86,
    chatDelete: 87,
  },
  course: {
    courseListView: 88,
    courseDetailView: 89,
    courseCreate: 90,
    courseUpdate: 91,
    courseDelete: 92,
    courseDeleteWithContents: 146,
  },
  supportTicket: {
    viewMyInitiatedTickets: 93,
    viewMyInitiatedTicketDetails: 94,
    viewMyAssignedTickets: 95,
    viewMyAssignedTicketDetails: 96,
    createTicket: 97,
    updateTicket: 98,
    escalateTicket: 99,
    resolveTicket: 100,
    deleteTicket: 101,
    viewEscalationList: 102,
    viewEscalationDetails: 103,
    createEscalation: 104,
    updateEscalation: 105,
    deleteEscalation: 106,
    reassign: 153,
    deEscalate: 152,
  },
  helpCenter: {
    CREATE: 108,
    UPDATE: 109,
    DELETE: 110,
    VIEW: 107,
  },
  attendance: {
    DETAIL: 112,
    CREATE: 113,
    UPDATE: 114,
    CONFIRM_ABSENCE: 115,
    LIST: 111,
    ADD_EXTENSION: 177,
    UPDATE_EXTENSION: 178,
    DELETE_EXTENSION: 179,
    UPDATE_GLOBAL_END_TIME: 180,
    VIEW_GLOBAL_END_TIME: 181,
  },
  COURSE_CONTENT: {
    READ: {
      COURSES_LIST_TEACHERS: 116,
      COURSES_LIST_STUDENTS: 118,
      COURSE_CONTENT_TEACHERS: 117,
      COURSE_CONTENT_STUDENTS: 119,
      PREVIEW_VIDEO: 120,
      PREVIEW_ATTACHMENT: 121,
      PREVIEW_ASSIGNMENT: 122,
      PREVIEW_EXAM: 123,
      VIEW_DELIVERED_EXAM: 124,
      VIEW_DELIVERED_ASSIGNMENT: 125,
    },
    CREATE: {
      TOPIC: 126,
      VIDEO: 127,
      ATTACHMENT: 128,
      ASSIGNMENT: 129,
      EXAM: 130,
      QUESTION: 131,
    },
    UPDATE: {
      TOPIC: 132,
      ASSIGNMENT: 133,
      EXAM: 134,
      QUESTION: 135,
      PUBLISH_ASSIGNMENT: 136,
      PUBLISH_EXAM: 137,
      START_EXAM: 138,
      START_ASSIGNMENT: 139,
    },
    DELETE: {
      TOPIC: 140,
      VIDEO: 141,
      ATTACHMENT: 142,
      ASSIGNMENT: 143,
      EXAM: 144,
      QUESTIONS: 145,
    },
  },
  LOGIN: {
    LOGIN_AS_USER: 147,
  },
  REPORTS: {
    READ: {
      Attendance_Summary_At_School_Level: 148,
      Daily_Attendance_At_Level_Level: 149,
      Attendance_Summary_At_Student_Level: 150,
      USERS_ACTIVATION_AT_SCHOOL_LEVEL: 154,
      ACTIVATION_AT_EMPLOYEE_LEVEL: 158,
      ACTIVATION_AT_STUDENT_LEVEL: 159,
      ACTIVATION_AT_GUARDIAN_LEVEL: 160,
      CONTENT_INTERACTION_AT_SCHOOL_LEVEL: 155,
      COMMUNICATIONS_AT_SCHOOL_LEVEL: 156,
      INTERACTION_COMMUNICATIONS_AT_TEACHER_LEVEL: 157,
    },
  },
  VCR: {
    READ: {
      VIEW_VCR_LIST_STUDENT: 161,
      VIEW_VCR_LIST_PERSONNEL: 162,
      VIEW_ADVANCED_FILTERS: 163,
      VIEW_VCR_DETAILS: 164,
      VIEW_RECORDINGS: 165,
      JOIN_VCR: 171,
    },
    CREATE: {
      ADD: 166,
      MANAGE_ATTENDANCE: 167,
      MANAGE_RECORDINGS: 168,
    },
    UPDATE: {
      UPDATE_VCR: 169,
    },
    DELETE: {
      DELETE_VCR: 170,
    },
  },
  TIME_PERIODS: {
    READ: {
      VIEW_TIME_PERIODS_LIST: 172,
      VIEW_TIME_PERIOD: 173,
    },
    CREATE: {
      CREATE_TIME_PERIOD: 174,
    },
    UPDATE: {
      UPDATE_TIME_PERIOD: 175,
    },
    DELETE: {
      DELETE_TIME_PERIOD: 176,
    },
  },
};
