<div class="relative flex flex-col items-stretch lg:w-52">
  @if (secondaryTitle()) {
    <div
      [ngClass]="{ 'cursor-pointer': !isDisabled() }"
      class="flex h-[2.875rem] items-center gap-2 rounded bg-[#2d324d] px-6 lg:p-2"
    >
      <app-hes-icon
        class="flex flex-shrink-0 text-2xl leading-6 text-primary"
        [hesIcon]="{ src: 'assets/icons/campus.svg' }"
      ></app-hes-icon>
      <span
        class="text-sm font-medium text-primary lg:flex-grow lg:font-semibold"
      >
        {{ secondaryTitle() }}
      </span>
    </div>
  }
  <div
    [ngClass]="{ 'cursor-pointer': !isDisabled(), 'lg:w-52': !collapsed() }"
    class="flex h-[2.875rem] items-center gap-2 rounded bg-[#2d324d] px-6 lg:p-2"
  >
    <app-hes-icon
      class="flex flex-shrink-0 text-2xl leading-6 text-primary"
      [hesIcon]="{ src: 'assets/icons/company.svg' }"
    ></app-hes-icon>
    @if (!collapsed()) {
      <span
        class="text-sm font-medium text-primary lg:flex-grow lg:font-semibold"
      >
        {{ title() }}
      </span>
    }
  </div>
  <div
    class="absolute right-2 top-1/2 flex h-5 w-5 -translate-y-1/2 transform items-center justify-center"
  >
    @if (!isDisabled()) {
      <fa-icon
        [icon]="currLang === 'en' ? faChevronRight : faChevronLeft"
        class="text-primary"
      ></fa-icon>
    }
  </div>
</div>
